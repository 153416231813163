import { DeviceInfo, LocationInfo, SessionInfo, UserContext } from "./types";

export class UserContextManager {
  private userContext: UserContext;
  private frontendFingerprint: string = "";

  constructor() {
    this.userContext = {
      fingerprint: "",
      deviceInfo: this.getDeviceInfo(),
      locationInfo: this.getLocationInfo(),
      sessionInfo: this.getSessionInfo()
    };
  }

  public async initialize(): Promise<void> {
    try {
      const components = [
        screen.width,
        screen.height,
        screen.colorDepth,
        navigator.hardwareConcurrency,
        this.getDeviceType(),
        this.getOSInfo().name,
        navigator.platform,
        navigator.language.split("-")[1],
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        navigator.language,
        this.getBrowserInfo().name,
        await this.getCanvasFingerprint(),
      ].join("|");

      const encoder = new TextEncoder();
      const data = encoder.encode(components);
      const hashBuffer = await crypto.subtle.digest("SHA-256", data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      this.frontendFingerprint = hashArray
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
    } catch (error) {
      console.error("Failed to initialize frontend fingerprint:", error);
      this.frontendFingerprint = this.generateFallbackFingerprint();
    }
  }

  public getFrontendFingerprint(): string {
    return this.frontendFingerprint;
  }

  public setContextId(contextId: string): void {
    this.userContext.fingerprint = contextId;
  }

  public getContext(): UserContext {
    return this.userContext;
  }

  private getDeviceInfo(): DeviceInfo {
    return {
      screenResolution: `${screen.width}x${screen.height}`,
      colorDepth: screen.colorDepth,
      deviceMemory: (navigator as any).deviceMemory || 0,
      hardwareConcurrency: navigator.hardwareConcurrency || 0,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      deviceType: this.getDeviceType(),
      browserName: this.getBrowserInfo().name,
      browserVersion: this.getBrowserInfo().version,
      osName: this.getOSInfo().name,
      osVersion: this.getOSInfo().version,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language
    };
  }

  private getDeviceType(): "Mobile" | "Tablet" | "Desktop" {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    }
    if (/Mobile|Android|iOS|iPhone|iPad/i.test(ua)) {
      return "Mobile";
    }
    return "Desktop";
  }

  private getBrowserInfo() {
    const ua = navigator.userAgent;
    const match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    return {
      name: match[1] || "",
      version: match[2] || "0"
    };
  }

  private getOSInfo() {
    const ua = navigator.userAgent;
    const match = ua.match(/(windows nt|mac os x|linux|android|ios) (\d+[\.\_\d]*)/i) || [];
    return {
      name: match[1] || "",
      version: match[2] || "0"
    };
  }

  private getLocationInfo(): LocationInfo {
    return {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language,
      countryCode: navigator.language.split("-")[1]
    };
  }

  private getSessionInfo(): SessionInfo {
    return {
      sessionId: `sess_${Date.now()}_${Math.random().toString(36).slice(2)}`,
      referrer: document.referrer,
      landingPage: window.location.href,
      startTime: new Date().toISOString()
    };
  }

  private async getCanvasFingerprint(): Promise<string> {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return "";

    canvas.width = 200;
    canvas.height = 50;
    ctx.textBaseline = "top";
    ctx.font = "14px Arial";
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText("Canvas Fingerprint", 2, 15);
    ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
    ctx.fillText("Canvas Fingerprint", 4, 17);

    return canvas.toDataURL();
  }

  private generateFallbackFingerprint(): string {
    return `fallback_${Date.now()}_${Math.random().toString(36).slice(2)}`;
  }
}
