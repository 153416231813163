import { BaseDialogManager } from "./dialogManager";
import {
  DialogEvent,
  createTrackingEvent,
  PageChangedEvent,
  BaseEvent,
  TrackEventFunction,
  FeedbackRequest,
  UserMetrics,
} from "../types";
import { generateSignupDialogHTML } from "./dialogHtml/getSignupDialog";
import { UserContextManager } from "../userContextManager";

export interface SignupEvent extends BaseEvent {
  type: "signup";
  data: SignupEventData;
}

export interface SignupEventData {
  type: string;
  email: string;
  name?: string;
  success: boolean;
  metrics?: UserMetrics;
  timeToSignup?: number;
  interactionsBeforeSignup?: number;
}

interface DialogContent {
  title: string;
  description: string;
  submitButtonText: string;
  thankYouTitle: string;
  thankYouMessage: string;
  successToast: string;
}

interface DialogConfig {
  content: DialogContent;
  styles?: Record<string, string>;
}

export class SignupDialogManager extends BaseDialogManager {
  private dialogContent: DialogContent = {
    title: "Stay Updated",
    description: "Get the latest insights, tips, and product updates delivered to your inbox",
    submitButtonText: "Keep Me Informed",
    thankYouTitle: "You're all set!",
    thankYouMessage: "Thanks for joining! We'll send you valuable updates and insights.",
    successToast: "You're now subscribed to updates!"
  };


  protected hoverStartTime: number | null = null;
  private focusStartTime: number | null = null;
  private firstInteractionTime: number | null = null;

  constructor(
    trackEvent: TrackEventFunction,
    sessionId: string,
    moonId: string,
    userContextManager: UserContextManager,
    userMetrics: UserMetrics,
  ) {
    super(trackEvent, sessionId, moonId, userContextManager, userMetrics);
    this.initializeDialog();
  }

  protected async initializeDialog(): Promise<void> {
    try {
      await this.fetchCustomContent();
    } catch (error) {
      console.warn("Failed to fetch custom content, using defaults:", error);
    }

    this.dialog = this.createDialog();
    document.body.appendChild(this.dialog);
    this.setupEventListeners();
  }

  private async fetchCustomContent(): Promise<void> {
    try {
      const response = await fetch("https://api.moonflows.com/api/dialog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          moonId: this.moonId,
          timestamp: new Date().toISOString(),
          currentUrl: window.location.href,
          referrerUrl: document.referrer || null,
          pathname: window.location.pathname,
          hostname: window.location.hostname,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch dialog config");
      }

      const resp = await response.json();
      const content: DialogConfig = resp.content;

      this.dialogContent = {
        ...this.dialogContent,
        ...content,
      };
    } catch (error) {
      console.error("Error fetching dialog content:", error);
    }
  }

  private createDialog(): HTMLDivElement {
    return generateSignupDialogHTML(this.dialogContent);
  }

  protected setupEventListeners(): void {
    const dialogContent = this.dialog.querySelector(
      ".moon_flows_dialog_content",
    );
    const emailInput = this.dialog.querySelector<HTMLInputElement>(
      ".moon_flows_email_input",
    );
    const nameInput = this.dialog.querySelector<HTMLInputElement>(
      ".moon_flows_name_input",
    );
    const submitBtn = this.dialog.querySelector<HTMLButtonElement>(
      ".moon_flows_submit_btn",
    );
    const closeBtn = this.dialog.querySelector<HTMLButtonElement>(
      ".moon_flows_close_btn",
    );

    if (!dialogContent || !emailInput || !submitBtn || !closeBtn) {
      console.error("Dialog is missing required elements");
      return;
    }

    // Track hover events
    dialogContent.addEventListener("mouseenter", () => {
      this.hoverStartTime = Date.now();
      const eventData = {
        type: "hover_start",
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("hover_start", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
          metrics: this.userMetrics,
        }),
      );
    });

    dialogContent.addEventListener("mouseleave", () => {
      if (this.hoverStartTime) {
        const hoverDuration = Date.now() - this.hoverStartTime;
        this.userMetrics.hoverDuration += hoverDuration;

        const eventData = {
          type: "hover_end",
          duration: hoverDuration,
        };

        this.trackEvent(
          createTrackingEvent<DialogEvent>("hover_end", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
            metrics: this.userMetrics,
          }),
        );
      }
    });

    // Handle form submission
    const form = this.dialog.querySelector('form');
    form?.addEventListener('submit', (e) => {
      e.preventDefault();
      const emailInput = this.dialog.querySelector<HTMLInputElement>(".moon_flows_email_input");
      const nameInput = this.dialog.querySelector<HTMLInputElement>(".moon_flows_name_input");
      if (emailInput) {
        void this.handleSubmit(emailInput, nameInput);
      }
    });

    // Handle close button
    closeBtn.addEventListener("click", () => {
      this.userMetrics.closeType = "button";
      this.isClosed = true;

      const eventData = {
        type: "dialog_closed",
        closeType: "button",
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("dialog_closed", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
          metrics: this.userMetrics,
        }),
      );

      this.hide();
    });

    emailInput.addEventListener("keydown", (e) => {
      if (e.key === "Backspace") {
        this.userMetrics.backspaces++;
        this.userMetrics.inputAttempts++;
      }
    });

    nameInput?.addEventListener("keydown", (e) => {
      if (e.key === "Backspace") {
        this.userMetrics.backspaces++;
        this.userMetrics.inputAttempts++;
      }
    });

    emailInput.addEventListener("input", () => {
      this.userMetrics.inputAttempts++;
      this.userMetrics.charactersTyped++;
      this.updateFormCompletionRate();
      this.userMetrics.interactions++;

      const eventData = {
        type: "input_change",
        field: emailInput.type,
        length: emailInput.value.length,
        completionRate: this.userMetrics.formCompletionRate,
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("input_change", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
          metrics: this.userMetrics,
        }),
      );
    });

    nameInput?.addEventListener("input", () => {
      this.userMetrics.inputAttempts++;
      this.userMetrics.charactersTyped++;
      this.updateFormCompletionRate();

      const eventData = {
        type: "input_change",
        field: nameInput.type,
        length: nameInput.value.length,
        completionRate: this.userMetrics.formCompletionRate,
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("input_change", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
          metrics: this.userMetrics,
        }),
      );
    });
  }

  private updateFormCompletionRate(): void {
    const emailInput = this.dialog.querySelector<HTMLInputElement>(
      ".moon_flows_email_input",
    );
    const nameInput = this.dialog.querySelector<HTMLInputElement>(
      ".moon_flows_name_input",
    );

    if (!emailInput) return;

    const emailWeight = 0.7; // Email is required
    const nameWeight = 0.3; // Name is optional

    const emailComplete = emailInput.value.length > 0 ? 1 : 0;
    const nameComplete = nameInput?.value.length ? 1 : 0;

    this.userMetrics.formCompletionRate =
      (emailComplete * emailWeight + nameComplete * nameWeight) * 100;
  }

  private async handleSubmit(
    emailInput: HTMLInputElement,
    nameInput: HTMLInputElement | null,
  ): Promise<void> {
    const email = emailInput.value;
    const name = nameInput?.value || "";

    if (!this.validateEmail(email)) {
      this.showError(emailInput, "Please enter a valid email address");
      this.userMetrics.validationErrors++;

      const eventData = {
        type: "validation_error",
        field: "email",
        totalErrors: this.userMetrics.validationErrors,
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("validation_error", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
          metrics: this.userMetrics,
        }),
      );
      return;
    }

    try {
      const feedbackRequest: FeedbackRequest = {
        feedback_type: 'signup',
        fingerprint: this.userContextManager.getFrontendFingerprint(),
        moon_id: this.moonId,
        session_id: this.sessionId,
        timestamp: Date.now(),
        values: {
          email,
          name,
        },
        metrics: this.userMetrics,
        user_context: this.userContextManager.getContext()
      };

      const response = await fetch("https://api.moonflows.com/api/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackRequest),
      });

      if (!response.ok) {
        throw new Error("Signup failed");
      }

      this.userMetrics.closeType = "submit";

      const signupData: SignupEventData = {
        type: "dialog_signup",
        email,
        name,
        success: true,
        metrics: this.userMetrics,
        timeToSignup: this.userMetrics.timeShown,
        interactionsBeforeSignup: this.userMetrics.interactions,
      };

      this.trackEvent(
        createTrackingEvent<SignupEvent>("signup", signupData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
          metrics: this.userMetrics,
        }),
      );

      this.showToast(this.dialogContent.successToast, "success");
      this.showThankYou();
    } catch (error) {
      console.error("Signup error:", error);

      const eventData = {
        type: "submit_error",
        error: error instanceof Error ? error.message : "Unknown error",
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("submit_error", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
          metrics: this.userMetrics,
        }),
      );

      this.showToast("Something went wrong. Please try again.", "error");
      const submitBtn = this.dialog.querySelector<HTMLButtonElement>(
        ".moon_flows_submit_btn",
      );
      if (submitBtn) {
        submitBtn.classList.remove("loading");
        submitBtn.textContent = this.dialogContent.submitButtonText;
      }
    }

    // Track submit attempt
    this.trackEvent(createTrackingEvent("submit_attempt", {
      attempts: this.userMetrics.submitAttempts
    }, {
      sessionId: this.sessionId,
      moonId: this.moonId,
      url: window.location.href,
      userContext: this.userContextManager.getContext(),
      metrics: this.userMetrics,
    }));
  }

  private showToast(
    message: string,
    type: "success" | "error" | "loading" = "success",
  ): void {
    const toast = this.dialog.querySelector(
      ".moon_flows_toast",
    ) as HTMLDivElement;
    if (!toast) {
      console.error("Toast element not found");
      return;
    }

    toast.style.transition = "none";
    toast.offsetHeight; // Force reflow
    toast.style.transition = "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)";

    const backgroundColor = {
      success: "#10B981",
      error: "#EF4444",
      loading: "#3B82F6",
    }[type];

    toast.style.background = backgroundColor;
    toast.textContent = message;
    toast.style.opacity = "1";
    toast.style.visibility = "visible";

    if (type !== "loading") {
      setTimeout(() => {
        toast.style.opacity = "0";
        setTimeout(() => {
          toast.style.visibility = "hidden";
        }, 300);
      }, 3000);
    }
  }

  private showThankYou(): void {
    const dialogContent = this.dialog.querySelector(
      ".moon_flows_dialog_content",
    ) as HTMLDivElement;

    dialogContent.innerHTML = `
      <h3 style="margin: 0; font-size: 20px; font-weight: 600; color: #000000;">
        ${this.dialogContent.thankYouTitle}
      </h3>
      <p style="margin: 12px 0; font-size: 15px; color: #666; line-height: 1.5;">
        ${this.dialogContent.thankYouMessage}
      </p>
    `;

    setTimeout(() => this.hide(), 10000);
  }

  public override handleServerCommand(command: any): void {
    if (command.action === "show_dialog") {
      const landingPages = ["/"];
      const currentPath = window.location.pathname;

      if (landingPages.includes(currentPath)) {
        this.show(true);
      } else {
        // Send event that the page has changed and shouldn't be tracking
        this.trackEvent(
          createTrackingEvent<PageChangedEvent>(
            "page_changed",
            {},
            {
              sessionId: this.sessionId,
              moonId: this.moonId,
              url: window.location.href,
              userContext: this.userContextManager.getContext(),
              metrics: this.userMetrics,
            },
          ),
        );
      }
    }
  }
}
