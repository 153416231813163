import { BaseDialogManager } from "./dialogManager";
import { generateFeedbackDialogHTML } from "./dialogHtml/getFeedbackDialog";
import {
  DialogEvent,
  createTrackingEvent,
  UserContext,
  TrackEventFunction,
  FeedbackRequest,
  UserMetrics,
} from "../types";
import { UserContextManager } from "../userContextManager";

export type StepType = "initial" | "detail" | "email" | "thank_you";

export interface StepFeedback {
  stepId: string;
  selectedOption?: string | null;
  detailFeedback?: string | null;
  email?: string;
  timestamp: number;
}

export interface StepSubmissionData {
  stepId: string;
  currentStep: StepType;
  moonId: string;
  sessionId: string;
  userContext: UserContext;
  metrics: UserMetrics;
  feedback: Partial<{
    option: string | null;
    detail: string | null;
    email: string;
  }>;
}

export interface FeedbackOption {
  id: string;
  label: string;
  icon: string;
}

export interface InitialStep {
  title: string;
  description: string;
  options: FeedbackOption[];
}

export interface DetailStep {
  title: string;
  description: string;
  placeholder: string;
}

export interface EmailStep {
  title: string;
  description: string;
  placeholder: string;
  submitButtonText: string;
}

export interface ThankYouStep {
  title: string;
  message: string;
}

export interface FeedbackDialogContent {
  steps: {
    initial: InitialStep;
    detail: DetailStep;
    email: EmailStep;
  };
  thankYou: ThankYouStep;
}

export class FeedbackDialogManager extends BaseDialogManager {
  private stepId: string;
  private currentFeedback: StepFeedback;
  private currentStep: StepType = "initial";

  private dialogContent: FeedbackDialogContent = {
    steps: {
      initial: {
        title: "Something not right?",
        description: "Let us know!",
        options: [
          {
            id: "price",
            label: "The price is not right for me",
            icon: "💰"
          },
          {
            id: "features",
            label: "I need specific features",
            icon: "🎯"
          },
          {
            id: "timing",
            label: "Still researching",
            icon: "⏰"
          },
          {
            id: "questions",
            label: "I have some questions",
            icon: "❓"
          }
        ],
      },
      detail: {
        title: "Tell us more",
        description: "We're listening - what would you like to know?",
        placeholder: "I'm wondering about...",
      },
      email: {
        title: "Thanks for your feedback!",
        description: "Let us get back to you.",
        placeholder: "you@company.com",
        submitButtonText: "Submit →",
      },
    },
    thankYou: {
      title: "Thank you!",
      message: "Your feedback is invaluable in helping us shape a better product for everyone.",
    },
  };

  constructor(
    trackEvent: TrackEventFunction,
    sessionId: string,
    moonId: string,
    userContextManager: UserContextManager,
    userMetrics: UserMetrics,
  ) {
    super(trackEvent, sessionId, moonId, userContextManager, userMetrics);
    this.stepId = this.generateStepId();
    this.currentFeedback = {
      stepId: this.stepId,
      timestamp: Date.now(),
    };
    this.initializeDialog();
  }

  private generateStepId(): string {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }

  protected initializeDialog(): void {
    if (!this.dialog) {
      const dialog = document.createElement("div");
      dialog.className = "moon_flows_dialog";
      dialog.style.cssText = `
        position: fixed;
        bottom: 24px;
        right: 24px;
        background: transparent;
        z-index: 99999;
        display: none;
        opacity: 0;
        transform: translateY(40px) scale(0.95);
        max-width: 420px;
        width: calc(100% - 48px);
        transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
      `;

      dialog.innerHTML = generateFeedbackDialogHTML(this.dialogContent);

      // Set initial step as active
      const initialStep = dialog.querySelector('[data-step="initial"]');
      if (initialStep) {
        initialStep.classList.add('active');
      }

      this.dialog = dialog;
      document.body.appendChild(dialog);
      this.setupEventListeners();
    }
  }

  protected setupEventListeners(): void {
    const dialogContent = this.dialog.querySelector(
      ".moon_flows_dialog_content",
    );
    if (!dialogContent) return;

    // Setup base event listeners
    this.setupBaseEventListeners(dialogContent);

    // Options click handlers
    const options = this.dialog.querySelectorAll(".moon_flows_option");
    options.forEach((option) => {
      option.addEventListener("click", async (e) => {
        this.userMetrics.interactions++;
        this.userMetrics.formInteractions++;
        const optionId = (e.currentTarget as HTMLElement).dataset.optionId || null;
        this.currentFeedback.selectedOption = optionId;

        if (!this.userMetrics.timeToFirstInteraction) {
          this.userMetrics.timeToFirstInteraction =
            Date.now() - (this.showStartTime || Date.now());
        }

        this.userMetrics.interactions++;
        this.userMetrics.formCompletionRate = this.calculateCompletionRate();

        await this.goToStep("detail");
        await this.submitStepFeedback("initial").catch(console.error);

        const eventData = {
          type: "input_change",
          field: "option",
          length: optionId?.length || 0,
          completionRate: this.userMetrics.formCompletionRate,
        };

        await this.trackEvent(
          createTrackingEvent<DialogEvent>("input_change", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
          })
        );
      });
    });

    // Detail submit handler
    const detailSubmit = this.dialog.querySelector(".moon_flows_detail_submit");
    detailSubmit?.addEventListener("click", async () => {
      this.userMetrics.interactions++;
      this.userMetrics.formInteractions++;
      this.userMetrics.submitAttempts++;
      const detailInput = this.dialog.querySelector(
        ".moon_flows_detail_input",
      ) as HTMLTextAreaElement;

      this.currentFeedback.detailFeedback = detailInput.value;
      this.currentFeedback.detailFeedback = detailInput.value;
      this.userMetrics.interactions++;
      this.userMetrics.formCompletionRate = this.calculateCompletionRate();

      if (detailInput.value.trim().length > 0) {
        await this.goToStep("email");

        // Submit feedback for the detail step
        await this.submitStepFeedback("detail");

        const eventData = {
          type: "input_change",
          field: "detail",
          length: detailInput.value.length,
          completionRate: this.userMetrics.formCompletionRate,
        };

        await this.trackEvent(
          createTrackingEvent<DialogEvent>("input_change", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
          })
        );
      } else {
        this.showError(detailInput, "Please provide some feedback");
        this.userMetrics.validationErrors++;

        const eventData = {
          type: "validation_error",
          field: "detail",
          totalErrors: this.userMetrics.validationErrors,
        };

        await this.trackEvent(
          createTrackingEvent<DialogEvent>("validation_error", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
          })
        );
      }
    });

    // Email input tracking
    const emailInput = this.dialog.querySelector(
      ".moon_flows_email_input",
    ) as HTMLInputElement;
    emailInput?.addEventListener("input", () => {
      if (!this.userMetrics.emailStarted && emailInput.value.length > 0) {
        this.userMetrics.emailStarted = true;
      }
      this.userMetrics.emailCompleted = emailInput.value.length > 0;
      this.userMetrics.charactersTyped++;
      this.userMetrics.formCompletionRate = this.calculateCompletionRate();
    });

    // Email submit handler
    const emailSubmit = this.dialog.querySelector(".moon_flows_email_submit");
    emailSubmit?.addEventListener("click", async (e) => {
      this.userMetrics.interactions++;
      this.userMetrics.formInteractions++;
      this.userMetrics.submitAttempts++;
      e.preventDefault();

      const emailInput = this.dialog.querySelector(
        ".moon_flows_email_input",
      ) as HTMLInputElement;

      this.userMetrics.submitAttempts++;

      if (this.validateEmailDialog(emailInput.value, emailInput)) {
        this.userMetrics.emailCompleted = true;
        this.userMetrics.formCompletionRate = this.calculateCompletionRate();
        this.currentFeedback.email = emailInput.value;

        // Show thank you step
        await this.goToStep("thank_you");

        // Submit final feedback with email
        await this.submitStepFeedback("email");

        // Auto-close dialog after delay
        setTimeout(() => {
          this.hide();
        }, 2000);

        const eventData = {
          type: "feedback_submitted",
          completionRate: this.userMetrics.formCompletionRate,
        };

        await this.trackEvent(
          createTrackingEvent<DialogEvent>("feedback_submitted", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
          })
        );
      } else {
        this.showError(emailInput, "Please enter a valid email");
        this.userMetrics.validationErrors++;

        const eventData = {
          type: "validation_error",
          field: "email",
          totalErrors: this.userMetrics.validationErrors,
        };

        await this.trackEvent(
          createTrackingEvent<DialogEvent>("validation_error", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
          })
        );
      }
    });

    // Close button handler
    const closeBtn = this.dialog.querySelector(".moon_flows_close_btn");
    closeBtn?.addEventListener("click", () => {
      this.isClosed = true; // Set the closed state
      this.userMetrics.closeType = "button";

      const eventData = {
        type: "dialog_closed",
        closeType: "button",
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("dialog_closed", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
        })
      );

      this.hide();
    });

    // Track hover states
    let hoverStartTime: number;

    dialogContent.addEventListener("mouseenter", () => {
      hoverStartTime = Date.now();

      const eventData = {
        type: "hover_start",
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("hover_start", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
        })
      );
    });

    dialogContent.addEventListener("mouseleave", () => {
      if (hoverStartTime) {
        const hoverDuration = Date.now() - hoverStartTime;
        this.userMetrics.hoverDuration += hoverDuration;

        const eventData = {
          type: "hover_end",
          duration: hoverDuration,
        };

        this.trackEvent(
          createTrackingEvent<DialogEvent>("hover_end", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
          })
        );
      }
    });
  }

  public async goToStep(step: StepType): Promise<void> {
    const container = this.dialog.querySelector(".moon_flows_dialog_content");
    const allSteps = container?.querySelectorAll(".moon_flows_step");

    if (allSteps) {
      // Add exit class to current step
      const currentStepEl = container?.querySelector('.moon_flows_step.active');
      if (currentStepEl) {
        currentStepEl.classList.add('exit');
      }

      // Remove active and exit classes after brief delay
      setTimeout(() => {
        allSteps.forEach(stepEl => {
          stepEl.classList.remove('active', 'exit');
        });

        // Add active class to target step
        const targetStep = container?.querySelector(`[data-step="${step}"]`);
        if (targetStep) {
          targetStep.classList.add('active');

          // Focus the input after transition
          setTimeout(() => {
            const input = targetStep.querySelector('input, textarea') as HTMLElement;
            if (input) {
              input.focus();
            }
          }, 300);
        }
      }, 150);

      // Update current step
      this.currentStep = step;

      // Track step transition
      await this.trackEvent(
        createTrackingEvent(
          "step_transition",
          {
            from: this.currentStep,
            to: step,
          },
          {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
          },
        ),
      );
    }
  }

  private calculateCompletionRate(): number {
    let completedSteps = 0;
    let totalSteps = 3; // option selection, detail, email

    if (this.currentFeedback.selectedOption) completedSteps++;
    if (
      this.currentFeedback.detailFeedback &&
      this.currentFeedback.detailFeedback.trim().length > 0
    )
      completedSteps++;
    if (this.userMetrics.emailCompleted) completedSteps++;

    return (completedSteps / totalSteps) * 100;
  }

  private async submitStepFeedback(step: StepType): Promise<void> {
    try {
      const values = {
        stepId: this.stepId,
        currentStep: step,
        option: step === "initial" ? this.currentFeedback.selectedOption : undefined,
        detail: step === "detail" ? this.currentFeedback.detailFeedback : undefined,
        email: step === "email" ? this.currentFeedback.email : undefined,
      };

      const request: FeedbackRequest = {
        feedback_type: 'feedback',
        fingerprint: this.userContextManager.getContext().fingerprint,
        moon_id: this.moonId,
        session_id: this.sessionId,
        timestamp: Date.now(),
        values,
        metrics: this.userMetrics,
        user_context: this.userContextManager.getContext()
      };

      const response = await fetch("https://api.moonflows.com/api/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      if (!response.ok) {
        throw new Error(`Failed to submit ${step} feedback`);
      }

      const eventData = {
        type: "step_submit_success",
        field: step,
        completionRate: this.userMetrics.formCompletionRate,
      };

      await this.trackEvent(
        createTrackingEvent<DialogEvent>("submit_success", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
        })
      );
    } catch (error) {
      console.error(`Error submitting ${step} feedback:`, error);

      const eventData = {
        type: "step_submit_error",
        field: step,
        error: error instanceof Error ? error.message : "Unknown error",
      };

      await this.trackEvent(
        createTrackingEvent<DialogEvent>("submit_error", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
        })
      );
    }
  }

  public show(): void {
    if (this.dialog) {
      this.dialog.style.display = 'block';
      // Use requestAnimationFrame to ensure the display change has taken effect
      requestAnimationFrame(() => {
        if (this.dialog) {
          this.dialog.style.opacity = '1';
          this.dialog.style.transform = 'translateY(0) scale(1)';
        }
      });
    }
  }

  public hide(): void {
    if (this.dialog) {
      this.dialog.style.opacity = '0';
      this.dialog.style.transform = 'translateY(40px) scale(0.95)';
      setTimeout(() => {
        if (this.dialog) {
          this.dialog.style.display = 'none';

          // Reset steps - show initial, hide others
          const allSteps = this.dialog.querySelectorAll('.moon_flows_step');
          allSteps.forEach(step => {
            step.classList.remove('active', 'exit');
          });
          const initialStep = this.dialog.querySelector('[data-step="initial"]');
          if (initialStep) {
            initialStep.classList.add('active');
          }

          this.currentStep = 'initial';
        }
      }, 300);
    }
  }

  private validateEmailDialog(email: string, emailInput: HTMLInputElement): boolean {
    if (!this.validateEmail(email)) {
      this.userMetrics.validationErrors++;
      this.showError(emailInput, "Please enter a valid email address");
      return false;
    }
    return true;
  }
}
