import { StepType } from "./dialogSetup/feedbackDialogManager";
import { PMFStepType } from "./dialogSetup/pmfDialogManager";
import { SignupEvent } from "./dialogSetup/signupDialogManager";

export type TrackEventFunction = (event: TypedTrackingEvent) => void;

// Base information interfaces
export interface DeviceInfo {
  screenResolution: string;
  colorDepth: number;
  deviceMemory: number;
  hardwareConcurrency: number;
  platform: string;
  userAgent: string;
  deviceType: "Mobile" | "Tablet" | "Desktop";
  browserName: string;
  browserVersion: string;
  osName: string;
  osVersion: string;
  timezone: string;
  language: string;
}

export interface LocationInfo {
  timezone: string;
  language: string;
  countryCode?: string;
  city?: string;
  region?: string;
}

export interface SessionInfo {
  sessionId: string;
  referrer: string;
  landingPage: string;
  startTime: string;
}

export interface UserContext {
  fingerprint: string;
  deviceInfo: DeviceInfo;
  locationInfo: LocationInfo;
  sessionInfo: SessionInfo;
}

export interface UserMetrics {
  // Core metrics
  timeOnPage: number;
  scrollDepth: number;
  interactions: number;
  totalInteractions: number;
  mouseDistance: number;
  clickCount: number;
  exitAttempts: number;
  lastPosition: Position;

  // Form metrics
  formInteractions: number;
  inputAttempts: number;
  charactersTyped: number;
  backspaces: number;
  formCompletionRate: number;
  submitAttempts: number;
  validationErrors: number;

  // Content interaction metrics
  copyEvents: number;
  textSelections: number;
  mediaInteractions: MediaInteractions;

  // Dialog metrics
  timeShown: number;
  timeToFirstInteraction: number | null;
  hoverDuration: number;
  focusDuration: number;
  closeType: string | null;

  // Form state tracking
  emailStarted: boolean;
  nameStarted: boolean;
  emailCompleted: boolean;
  nameCompleted: boolean;
}

export interface MediaInteractions {
  plays: number;
  pauses: number;
  completions: number;
}

// Add these interfaces for feedback events
export interface FeedbackEventData {
  type: "feedback_submitted" | "option_selected" | "detail_submitted";
  selectedOption?: string;
  detailFeedback?: string;
  email?: string;
  success: boolean;
  userMetrics?: UserMetrics;
  timeToComplete?: number;
  interactionsBeforeSubmit?: number;
}

export interface FeedbackEvent extends BaseEvent {
  type: "feedback_submitted" | "option_selected" | "detail_submitted";
  data: FeedbackEventData;
}

// Dialog specific types
export type DialogEventType =
  | "dialog_shown"
  | "dialog_closed"
  | "dialog_signup"
  | "hover_start"
  | "hover_end"
  | "input_focus"
  | "input_blur"
  | "input_change"
  | "input_backspace"
  | "validation_error"
  | "submit_attempt"
  | "submit_success"
  | "submit_error"
  | "metrics_update"
  | "option_selected"
  | "detail_submitted"
  | "feedback_submitted"
  | "step_submit_success"
  | "step_submit_error"
  | "step_transition"
  | "survey_completed";

// Core event types
export type CoreEventType =
  | "scroll"
  | "click"
  | "move"
  | "signup"
  | "exit_intent"
  | "form_interaction"
  | "copy"
  | "media_interaction"
  | "page_changed"
  | "text_selection";

// Combined event types
export type EventType = CoreEventType | DialogEventType;

export interface Position {
  x: number;
  y: number;
}

// Base event interface
export interface BaseEvent {
  type: EventType;
  timestamp: string;
  sessionId: string;
  moonId: string;
  url: string;
  userContext: UserContext;
  metrics?: Partial<UserMetrics>;
  data: any;
}

// Specific event interfaces
export interface ScrollEvent extends BaseEvent {
  type: "scroll";
  data: {
    depth: number;
    position: number;
    viewportHeight: number;
    documentHeight: number;
  };
}

export interface ClickEvent extends BaseEvent {
  type: "click";
  data: {
    position: { x: number; y: number };
    element?: {
      tag: string;
      id: string;
      class: string;
      path: string;
      text?: string;
    };
  };
}

export interface MoveEvent extends BaseEvent {
  type: "move";
  data: {
    distance: number;
    position: { x: number; y: number };
  };
}


// Configuration and server communication interfaces
export interface BatchConfig {
  maxSize: number;
  interval: number;
  maxRetries: number;
  retryDelay: number;
}

// Server command types
export interface ShowDialogPayload {
  title?: string;
  message: string;
  type?: "info" | "warning" | "error" | "success";
  buttons?: Array<{
    text: string;
    action: string;
    style?: "primary" | "secondary" | "danger";
  }>;
  timeout?: number;
}

export interface UpdateConfigPayload {
  batchSize?: number;
  interval?: number;
  retryDelay?: number;
}

export interface TriggerEventPayload {
  eventType: string;
  eventData: Record<string, unknown>;
}

export type ServerCommand =
  | { type: "SHOW_DIALOG"; payload: ShowDialogPayload }
  | { type: "UPDATE_CONFIG"; payload: UpdateConfigPayload }
  | { type: "TRIGGER_EVENT"; payload: TriggerEventPayload }
  | { type: "CLEAR_QUEUE" }
  | { type: "REFRESH_CONTEXT" }
  | { type: "PAUSE_TRACKING" }
  | { type: "RESUME_TRACKING" };

export interface BatchResponse {
  success: boolean;
  commands?: ServerCommand[];
  message?: string;
  timestamp?: number;
  result?: string;
}

export function createTrackingEvent<T extends TypedTrackingEvent>(
  type: T["type"],
  data: T["data"],
  baseData: Omit<BaseEvent, 'type' | 'data' | 'timestamp'>
): T {
  return {
    type,
    data,
    timestamp: new Date().toISOString(),
    ...baseData,
    metrics: baseData.metrics ?? {} as Partial<UserMetrics>
  } as T;
}

export interface FormInteractionEvent extends BaseEvent {
  type: "form_interaction";
  data: {
    elementType: string;
    elementId: string;
    elementName: string;
    inputType?: string;
  };
}

export interface ExitIntentEvent extends BaseEvent {
  type: "exit_intent";
  data: {
    position: { x: number; y: number };
  };
}

export interface CopyEvent extends BaseEvent {
  type: "copy";
  data: Record<string, never>;
}

export interface SelectionEvent extends BaseEvent {
  type: "text_selection";
  data: {
    selection?: string;
  };
}

export interface PageChangedEvent extends BaseEvent {
  type: "page_changed";
  data: {};
}

export interface DialogEvent extends BaseEvent {
  type: DialogEventType;
  data: {}
}

// Update TypedTrackingEvent
export type TypedTrackingEvent =
  | ScrollEvent
  | ClickEvent
  | MoveEvent
  | DialogEvent
  | SignupEvent
  | FormInteractionEvent
  | ExitIntentEvent
  | CopyEvent
  | SelectionEvent
  | PageChangedEvent
  | FeedbackEvent
  | MediaInteractionEvent;

export interface FeedbackRequest {
  feedback_type: 'signup' | 'feedback' | 'pmf';
  fingerprint: string;
  moon_id: string;
  session_id: string;
  timestamp: number;
  values: Record<string, any>;
  metrics: UserMetrics;
  user_context: UserContext;
}

export interface BatchRequestBody {
  events: EventWrapper[];
  metrics?: Partial<UserMetrics>;
  timestamp: number;
  moonId: string;
  contextId: string;
}

export interface EventWrapper {
  type: EventType;
  data: any;
}

// Add the interface
export interface MediaInteractionEvent extends BaseEvent {
  type: "media_interaction";
  data: {
    action: "play" | "pause" | "complete";
    mediaId: string;
    mediaType: string;
    time: number;
    duration: number;
  };
}
