interface DialogContent {
  title: string;
  description: string;
  submitButtonText: string;
  thankYouTitle: string;
  thankYouMessage: string;
}

export function generateSignupDialogHTML(dialogContent: DialogContent) {
  console.log("Get Dialog and Style: ", dialogContent);

  const dialog = document.createElement("div");
  dialog.className = "moon_flows_dialog";
  dialog.style.cssText = `
      position: fixed;
      bottom: 24px;
      right: 24px;
      background: transparent;
      z-index: 99999;
      display: none;
      opacity: 0;
      transform: translateY(40px) scale(0.95);
      max-width: 420px;
      width: calc(100% - 48px);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
      transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
      text-rendering: geometricPrecision;
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'kern' 1, 'liga' 1;
      font-synthesis: none;
      user-select: none;

      @media (max-width: 480px) {
          bottom: 0;
          right: 50%;
          transform: translate(50%, 40px) scale(0.95);
          width: calc(100% - 32px);
          max-width: none;
      }
    `;

  dialog.innerHTML = `
    <form class="moon_flows_dialog_content" style="
      background: rgba(23, 23, 28, 0.90);
      border-radius: 24px;
      box-shadow: 0 24px 48px rgba(0, 0, 0, 0.4);
      padding: 24px;
      padding-right: 48px;
      width: 100%;
      max-width: 380px;
      margin: 0 auto;
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: #ffffff;
      position: relative;
      overflow: visible;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      animation: moonflowsSlideUp 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    ">
      <style>
        @keyframes moonflowsSlideUp {
          0% {
            opacity: 0;
            transform: translateY(20px) scale(0.98);
          }
          100% {
            opacity: 1;
            transform: translateY(0) scale(1);
          }
        }

        @keyframes moonflowsSuccess {
          0% {
            transform: scale(0.8);
            opacity: 0;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }

        .moon_flows_input_field {
          width: 100%;
          padding: 16px 20px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 16px;
          font-size: 15px;
          margin-bottom: 16px;
          transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
          background: rgba(255, 255, 255, 0.08);
          color: #ffffff;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }

        .moon_flows_input_field::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        .moon_flows_input_field:hover {
          background: rgba(255, 255, 255, 0.1);
          border-color: rgba(255, 255, 255, 0.2);
        }

        .moon_flows_input_field:focus {
          outline: none;
          background: rgba(255, 255, 255, 0.12);
          border-color: rgba(255, 255, 255, 0.3);
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        }

        .moon_flows_submit_btn {
          background: rgba(255, 255, 255, 0.1);
          color: #ffffff;
          padding: 16px 24px;
          border-radius: 16px;
          font-weight: 600;
          font-size: 15px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          cursor: pointer;
          transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
          width: 100%;
          position: relative;
          overflow: hidden;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }

        .moon_flows_submit_btn:hover {
          transform: translateY(-2px);
          background: rgba(255, 255, 255, 0.15);
          border-color: rgba(255, 255, 255, 0.2);
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        }

        .moon_flows_submit_btn:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            45deg,
            transparent 0%,
            rgba(255, 255, 255, 0.1) 50%,
            transparent 100%
          );
          transform: translateX(-100%);
          transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
        }

        .moon_flows_submit_btn:hover:before {
          transform: translateX(100%);
        }

        .moon_flows_close_btn {
          position: absolute;
          top: 16px;
          right: 12px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.1);
          cursor: pointer;
          color: #ffffff;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
          font-size: 18px;
          z-index: 100;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }

        .moon_flows_close_btn:hover {
          background: rgba(255, 255, 255, 0.15);
          border-color: rgba(255, 255, 255, 0.2);
          transform: rotate(90deg);
        }

        .moon_flows_title {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 12px;
          color: #ffffff;
          letter-spacing: -0.02em;
        }

        .moon_flows_description {
          font-size: 15px;
          color: rgba(255, 255, 255, 0.9);
          margin-bottom: 24px;
          line-height: 1.5;
        }

        .moon_flows_thank_you {
          text-align: center;
          padding: 40px 20px;
        }

        .moon_flows_thank_you .moon_flows_success_icon {
          margin-bottom: 24px;
          animation: moonflowsSuccess 0.5s cubic-bezier(0.16, 1, 0.3, 1) forwards;
        }

        .moon_flows_thank_you .moon_flows_title {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 16px;
          color: #ffffff;
        }

        .moon_flows_thank_you .moon_flows_description {
          font-size: 15px;
          line-height: 1.6;
          color: rgba(255, 255, 255, 0.9);
          max-width: 300px;
          margin: 0 auto;
        }

        @media (max-width: 480px) {
          .moon_flows_dialog_content {
            padding: 20px;
            padding-right: 44px;
            border-radius: 20px;
          }
          .moon_flows_title {
            font-size: 16px;
            letter-spacing: -0.01em;
          }
          .moon_flows_description {
            font-size: 14px;
            letter-spacing: 0;
          }
          .moon_flows_input_field,
          .moon_flows_submit_btn {
            padding: 12px 16px;
            font-size: 14px;
            letter-spacing: 0;
          }
        }
      </style>
      <button type="button" class="moon_flows_close_btn" aria-label="Close dialog">×</button>
      <h3 class="moon_flows_title">
        ${dialogContent.title || "Join now"}
      </h3>
      <p class="moon_flows_description">
        ${dialogContent.description || "Stay up to date on our latest releases"}
      </p>
      <input type="text"
        name="name"
        placeholder="Enter your name (optional)"
        class="moon_flows_input_field moon_flows_name_input"
        style="
          width: 100%;
          margin-bottom: 6px;
          border-radius: 8px;
          box-sizing: border-box;
          outline: none;
        "
      >
      <input type="email"
        placeholder="Enter your email"
        required
        class="moon_flows_input_field moon_flows_email_input"
        style="
          width: 100%;
          margin-bottom: 8px;
          border-radius: 8px;
          box-sizing: border-box;
          outline: none;
        "
      >
      <button type="submit" class="moon_flows_submit_btn" style="
        width: 100%;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        color: white;
        padding: 8px;
        font-size: 14px;
        font-weight: 500;
      ">${dialogContent.submitButtonText || "Join Now"}</button>
    </form>
  `;

  // Add form submit handler
  const form = dialog.querySelector('form');
  form?.addEventListener('submit', (e) => {
    e.preventDefault();
    const submitBtn = dialog.querySelector<HTMLButtonElement>('.moon_flows_submit_btn');
    if (submitBtn) {
      submitBtn.click();
    }
  });

  function showThankYou() {
    const content = dialog.querySelector('.moon_flows_dialog_content');
    if (!content) return;

    content.innerHTML = `
        <div class="moon_flows_thank_you" style="
            text-align: center;
            padding: 20px;
            min-height: 320px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        ">
            <div class="moon_flows_success_icon" style="margin-bottom: 24px;">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="32" cy="32" r="32" fill="#22C55E" fill-opacity="0.1"/>
                    <circle cx="32" cy="32" r="24" fill="#22C55E" fill-opacity="0.2"/>
                    <circle cx="32" cy="32" r="16" fill="#22C55E"/>
                    <path d="M40 26L30 36L26 32" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <h3 class="moon_flows_title">${dialogContent.thankYouTitle}</h3>
            <p class="moon_flows_description">${dialogContent.thankYouMessage}</p>
        </div>
    `;
  }

  return dialog;
}

function setupSignupListeners(dialog: HTMLElement) {
  // When showing the dialog
  dialog.style.display = 'block';
  requestAnimationFrame(() => {
    dialog.style.opacity = '1';
    if (window.innerWidth <= 480) {
      dialog.style.transform = 'translate(50%, 0) scale(1)';
    } else {
      dialog.style.transform = 'translateY(0) scale(1)';
    }
  });

  // When hiding the dialog
  function hideDialog() {
    dialog.style.opacity = '0';
    if (window.innerWidth <= 480) {
      dialog.style.transform = 'translate(50%, 40px) scale(0.95)';
    } else {
      dialog.style.transform = 'translateY(40px) scale(0.95)';
    }
    setTimeout(() => {
      dialog.style.display = 'none';
    }, 300);
  }
}
