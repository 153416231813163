// First, let's create a unified base dialog class that both specific dialogs can extend
import { EventTracker } from "../eventTracker";
import { UserContextManager } from "../userContextManager";
import {
  BaseEvent,
  createTrackingEvent,
  DialogEvent,
  TrackEventFunction,
  UserMetrics,
  Position,
  TypedTrackingEvent,
  EventType
} from "../types";
import { cleanMetrics } from "../utils";

export abstract class BaseDialogManager {
  protected dialog!: HTMLDivElement;
  protected trackEvent: TrackEventFunction;
  protected sessionId: string;
  protected moonId: string;
  protected userContextManager: UserContextManager;
  protected isShowing: boolean = false;
  protected isCompleted: boolean = false;
  protected isClosed: boolean = false;
  protected showStartTime: number | null = null;
  protected metricsInterval: number | null = null;
  protected hoverStartTime: number | null = null;
  protected lastPosition: Position = { x: 0, y: 0 };
  protected userMetrics: UserMetrics;

  constructor(
    trackEvent: TrackEventFunction,
    sessionId: string,
    moonId: string,
    userContextManager: UserContextManager,
    userMetrics: UserMetrics,
  ) {
    this.trackEvent = trackEvent;
    this.sessionId = sessionId;
    this.moonId = moonId;
    this.userContextManager = userContextManager;
    this.userMetrics = userMetrics;

    // Initialize dialog-specific metrics
    this.userMetrics.timeShown = 0;
    this.userMetrics.timeToFirstInteraction = null;
    this.userMetrics.hoverDuration = 0;
    this.userMetrics.focusDuration = 0;
    this.userMetrics.inputAttempts = 0;
    this.userMetrics.charactersTyped = 0;
    this.userMetrics.backspaces = 0;
    this.userMetrics.formCompletionRate = 0;
    this.userMetrics.closeType = null;
    this.userMetrics.emailStarted = false;
    this.userMetrics.nameStarted = false;
    this.userMetrics.emailCompleted = false;
    this.userMetrics.nameCompleted = false;
    this.userMetrics.submitAttempts = 0;
    this.userMetrics.validationErrors = 0;
  }

  protected getMetricsSnapshot(): UserMetrics {
    return this.userMetrics;
  }

  protected validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  protected showError(inputElement: HTMLElement, message: string): void {
    inputElement.style.borderColor = "#ff4444";
    const errorDiv = document.createElement("div");
    errorDiv.className = "moon_flows_error";
    errorDiv.style.cssText = `
      color: #ff4444;
      font-size: 14px;
      margin-top: -8px;
      margin-bottom: 12px;
    `;
    errorDiv.textContent = message;

    const existingError =
      inputElement.parentElement?.querySelector(".moon_flows_error");
    if (existingError) {
      existingError.remove();
    }

    inputElement.parentElement?.insertBefore(
      errorDiv,
      inputElement.nextSibling,
    );

    setTimeout(() => {
      inputElement.style.borderColor = "#e5e5e5";
      errorDiv.remove();
    }, 5000);
  }

  protected startMetricsTracking(): void {
    this.showStartTime = Date.now();
    this.metricsInterval = window.setInterval(() => {
      if (this.showStartTime) {
        this.userMetrics.timeShown = Math.floor(
          (Date.now() - this.showStartTime) / 1000,
        );
      }

      const eventData = {
        type: "metrics_update",
        timeShown: this.userMetrics.timeShown,
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("metrics_update", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
        }),
      );
    }, 1000) as unknown as number;
  }

  protected stopMetricsTracking(): void {
    if (this.metricsInterval !== null) {
      window.clearInterval(this.metricsInterval);
      this.metricsInterval = null;
    }
  }

  public show(serverTriggered = false): void {
    if (this.isClosed || this.isCompleted) {
      console.log("Dialog was previously closed or completed - ignoring show command");
      return;
    }

    if (!this.isShowing) {
      this.dialog.style.display = "block";
      requestAnimationFrame(() => {
        this.dialog.style.opacity = "1";
        this.dialog.style.transform =
          window.innerWidth <= 768 ? "translateY(0)" : "translateY(0) scale(1)";
      });
      this.isShowing = true;
      this.userMetrics.interactions++;

      const eventData = {
        type: "dialog_shown",
        trigger: serverTriggered ? "server" : "client",
      };

      this.trackEvent(
        createTrackingEvent<DialogEvent>("dialog_shown", eventData, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
        }),
      );
    }
  }

  public hide(): void {
    if (this.isShowing) {
      this.stopMetricsTracking();
      // this.userMetrics.interactions++;
      this.dialog.style.opacity = "0";
      this.dialog.style.transform =
        window.innerWidth <= 768
          ? "translateY(100%)"
          : "translateY(40px) scale(0.95)";

      setTimeout(() => {
        this.dialog.style.display = "none";
        this.isShowing = false;

        const eventData = {
          type: "dialog_closed",
          closeType: this.userMetrics.closeType || "timeout",
        };

        this.trackEvent(
          createTrackingEvent<DialogEvent>("dialog_closed", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
          }),
        );
      }, 500);
    }
  }

  public handleServerCommand(command: any): void {
    if (command.action === "show_dialog") {
      if (!this.isShowing) {
        this.show(true);
      }
    } else if (command.action === "hide_dialog") {
      this.hide();
    }
  }

  protected abstract initializeDialog(): void;
  protected abstract setupEventListeners(): void;

  protected setupBaseEventListeners(dialogContent: Element): void {
    // Track hover events
    dialogContent?.addEventListener('mouseenter', () => {
      if (this.userMetrics.timeToFirstInteraction === null) {
        this.userMetrics.timeToFirstInteraction = Date.now() - (this.showStartTime || Date.now());
      }
      this.trackInteraction("hover_start", { duration: 0 });
      this.hoverStartTime = Date.now();
    });

    dialogContent?.addEventListener('mouseleave', () => {
      if (this.hoverStartTime) {
        const hoverDuration = Date.now() - this.hoverStartTime;
        this.userMetrics.hoverDuration += hoverDuration;
        this.trackInteraction("hover_end", { duration: hoverDuration });
      }
    });

    // Track input events
    dialogContent?.addEventListener('input', (e: Event) => {
      const target = e.target as HTMLInputElement;
      this.userMetrics.charactersTyped++;
      this.trackInteraction("input_change", {
        field: target.name,
        length: target.value.length,
        completionRate: this.calculateFieldCompletionRate(target)
      });
    });
  }

  private calculateFieldCompletionRate(input: HTMLInputElement): number {
    const minLength = input.minLength || 3;
    return Math.min((input.value.length / minLength) * 100, 100);
  }

  protected goToStep(step: string): void {
    const container = this.dialog.querySelector(".moon_flows_dialog_content");
    const allSteps = container?.querySelectorAll(".moon_flows_step");

    if (allSteps) {
      // Add exit class to current step
      const currentStepEl = container?.querySelector('.moon_flows_step.active');
      if (currentStepEl) {
        currentStepEl.classList.add('exit');
      }

      // Remove active and exit classes after brief delay
      setTimeout(() => {
        allSteps.forEach(stepEl => {
          stepEl.classList.remove('active', 'exit');
        });

        // Add active class to target step
        const targetStep = container?.querySelector(`[data-step="${step}"]`);
        if (targetStep) {
          targetStep.classList.add('active');

          // Focus the input after transition
          setTimeout(() => {
            const input = targetStep.querySelector('input, textarea') as HTMLElement;
            if (input) {
              input.focus();
            }
          }, 300);
        }
      }, 150);
    }
  }

  protected updateMetrics(): void {
    const now = Date.now();
    this.userMetrics.timeShown = now - (this.showStartTime || now);

    // Update interaction metrics
    if (this.userMetrics.timeToFirstInteraction === null && this.userMetrics.interactions > 0) {
      this.userMetrics.timeToFirstInteraction = now - (this.showStartTime || now);
    }

    // Update total interactions
    this.userMetrics.totalInteractions = this.calculateTotalInteractions();

    // Track form completion metrics
    if (this.userMetrics.emailStarted || this.userMetrics.nameStarted) {
      this.userMetrics.formCompletionRate =
        ((this.userMetrics.emailCompleted ? 1 : 0) +
          (this.userMetrics.nameCompleted ? 1 : 0)) /
        ((this.userMetrics.emailStarted ? 1 : 0) +
          (this.userMetrics.nameStarted ? 1 : 0)) * 100;
    }
  }

  protected trackInteraction(type: EventType, data: any = {}): void {
    this.updateMetrics();

    // Send the event without metrics
    this.trackEvent(
      createTrackingEvent(type, data, {
        sessionId: this.sessionId,
        moonId: this.moonId,
        url: window.location.href,
        userContext: this.userContextManager.getContext(),
      })
    );

  }

  private calculateTotalInteractions(): number {
    return (
      this.userMetrics.interactions +
      this.userMetrics.formInteractions +
      this.userMetrics.copyEvents +
      this.userMetrics.textSelections +
      this.userMetrics.mediaInteractions.plays +
      this.userMetrics.mediaInteractions.pauses +
      this.userMetrics.mediaInteractions.completions
    );
  }

  private lastSentMetrics: string = '';
  private metricsUpdateTimeout: NodeJS.Timeout | null = null;
}


