import { FeedbackDialogContent } from "../feedbackDialogManager";

export function generateFeedbackDialogHTML(content: FeedbackDialogContent) {
  const baseHTML = `
    <div class="moon_flows_toast_container" style="
      position: absolute;
      top: -48px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      display: flex;
      justify-content: center;
      width: 100%;
      pointer-events: none;
      z-index: 1000;
    ">
      <div class="moon_flows_toast" style="
        background: rgba(24, 24, 27, 0.8);
        color: white;
        padding: 8px 16px;
        border-radius: 12px;
        font-size: 13px;
        font-weight: 500;
        opacity: 0;
        transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
        text-align: center;
        min-width: 160px;
        max-width: 90%;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
        pointer-events: auto;
        border: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
      "></div>
    </div>
    <div class="moon_flows_dialog_content" style="
      background: rgba(23, 23, 28, 0.90);
      border-radius: 24px;
      box-shadow: 0 24px 48px rgba(0, 0, 0, 0.4);
      padding: 24px;
      padding-right: 48px;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: #ffffff;
      position: relative;
      overflow: visible;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      animation: moonflowsSlideUp 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      text-rendering: geometricPrecision;
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'kern' 1, 'liga' 1;
      font-synthesis: none;
    ">
      <style>
        @keyframes moonflowsSlideUp {
          0% {
            opacity: 0;
            transform: translateY(20px) scale(0.98);
          }
          100% {
            opacity: 1;
            transform: translateY(0) scale(1);
          }
        }

        .moon_flows_steps_wrapper {
          position: relative;
          width: 100%;
          overflow: visible;
          min-height: 200px;
        }

        .moon_flows_step {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: auto;
          opacity: 0;
          visibility: hidden;
          transform: translateX(40px);
          transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
        }

        .moon_flows_step.active {
          position: relative;
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
        }

        .moon_flows_step.exit {
          opacity: 0;
          visibility: hidden;
          transform: translateX(-40px);
          transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
        }

        .moon_flows_option {
          transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
          cursor: pointer;
          background: rgba(255, 255, 255, 0.05);
          padding: 16px 20px;
          border-radius: 16px;
          margin-bottom: 12px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          font-weight: 500;
          color: #ffffff;
          font-size: 15px;
          display: flex;
          align-items: center;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }

        .moon_flows_option:hover {
          background: rgba(255, 255, 255, 0.1);
          border-color: rgba(255, 255, 255, 0.2);
          transform: translateY(-2px);
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        }

        .moon_flows_input {
          width: 100%;
          padding: 16px 20px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 16px;
          font-size: 15px;
          margin-bottom: 16px;
          transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
          background: rgba(255, 255, 255, 0.05);
          color: #ffffff;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }

        .moon_flows_input:focus {
          outline: none;
          border-color: rgba(255, 255, 255, 0.3);
          background: rgba(255, 255, 255, 0.1);
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        }

        .moon_flows_input::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        .moon_flows_submit_btn {
          background: rgba(255, 255, 255, 0.1);
          color: #ffffff;
          padding: 16px 24px;
          border-radius: 16px;
          font-weight: 600;
          font-size: 15px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          cursor: pointer;
          transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
          width: 100%;
          position: relative;
          overflow: hidden;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }

        .moon_flows_submit_btn:hover {
          transform: translateY(-2px);
          background: rgba(255, 255, 255, 0.15);
          border-color: rgba(255, 255, 255, 0.2);
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        }

        .moon_flows_submit_btn:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            45deg,
            transparent 0%,
            rgba(255, 255, 255, 0.1) 50%,
            transparent 100%
          );
          transform: translateX(-100%);
          transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
        }

        .moon_flows_submit_btn:hover:before {
          transform: translateX(100%);
        }

        .moon_flows_title {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 12px;
          color: #ffffff;
          letter-spacing: -0.02em;
        }

        .moon_flows_description {
          font-size: 15px;
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 24px;
          line-height: 1.5;
        }

        .moon_flows_close_btn {
          position: absolute;
          top: 16px;
          right: 12px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.1);
          cursor: pointer;
          color: #ffffff;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
          font-size: 18px;
          z-index: 100;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }

        .moon_flows_close_btn:hover {
          background: rgba(255, 255, 255, 0.15);
          border-color: rgba(255, 255, 255, 0.2);
          transform: rotate(90deg);
        }

        @media (max-width: 480px) {
          .moon_flows_dialog_content {
            padding: 24px;
            border-radius: 20px;
          }
          .moon_flows_title {
            font-size: 16px;
            letter-spacing: -0.01em;
          }
          .moon_flows_description {
            font-size: 14px;
            letter-spacing: 0;
          }
          .moon_flows_option,
          .moon_flows_input,
          .moon_flows_submit_btn {
            padding: 12px 16px;
            font-size: 14px;
            letter-spacing: 0;
          }
        }
      </style>

      <div class="moon_flows_steps_wrapper">
        <div class="moon_flows_steps_container">
          <!-- Initial Step -->
          <div class="moon_flows_step" data-step="initial">
            <h3 class="moon_flows_title">${content.steps.initial.title}</h3>
            <p class="moon_flows_description">${content.steps.initial.description}</p>
            <div class="moon_flows_options" role="radiogroup">
              ${content.steps.initial.options
      .map(
        (option) => `
                  <div class="moon_flows_option"
                    data-option-id="${option.id}"
                    role="radio"
                    tabindex="0"
                    aria-checked="false">
                    <span class="moon_flows_option_icon">${option.icon}</span>
                    <span>${option.label}</span>
                  </div>
                `,
      )
      .join("")}
            </div>
          </div>

          <!-- Detail Step -->
          <div class="moon_flows_step" data-step="detail">
            <h3 class="moon_flows_title">${content.steps.detail.title}</h3>
            <p class="moon_flows_description">${content.steps.detail.description}</p>
            <textarea
              class="moon_flows_input moon_flows_detail_input"
              placeholder="${content.steps.detail.placeholder}"
              rows="3"
              style="resize: none; min-height: 80px;"
            ></textarea>
            <button class="moon_flows_submit_btn moon_flows_detail_submit">Continue</button>
          </div>

          <!-- Email Step -->
          <div class="moon_flows_step" data-step="email">
            <h3 class="moon_flows_title">${content.steps.email.title}</h3>
            <p class="moon_flows_description">${content.steps.email.description}</p>
            <form class="moon_flows_email_form">
              <input
                type="email"
                class="moon_flows_input moon_flows_email_input"
                placeholder="${content.steps.email.placeholder}"
                required
              />
              <button type="submit" class="moon_flows_submit_btn moon_flows_email_submit">
                ${content.steps.email.submitButtonText}
              </button>
            </form>
          </div>

          <!-- Thank You Step -->
          <div class="moon_flows_step" data-step="thank_you">
            <div class="moon_flows_thank_you" style="
                text-align: center;
                padding: 20px;
                min-height: 320px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            ">
                <div class="moon_flows_success_icon" style="margin-bottom: 24px;">
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="32" cy="32" r="32" fill="#22C55E" fill-opacity="0.1"/>
                        <circle cx="32" cy="32" r="24" fill="#22C55E" fill-opacity="0.2"/>
                        <circle cx="32" cy="32" r="16" fill="#22C55E"/>
                        <path d="M40 26L30 36L26 32" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <h3 class="moon_flows_title">${content.thankYou.title}</h3>
                <p class="moon_flows_description">${content.thankYou.message}</p>
            </div>
          </div>
        </div>
      </div>

      <button class="moon_flows_close_btn" aria-label="Close dialog">×</button>
    </div>
  `;

  return baseHTML;
}
