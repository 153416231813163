import { ElementData, ScrollData } from './events';

export class EventHandlers {
  static readonly DEBOUNCE_TIME = 200;

  static handleMove(x: number, y: number, lastPosition: {x: number, y: number}): {
    distance: number;
    newPosition: {x: number, y: number};
  } {
    const dx = x - lastPosition.x;
    const dy = y - lastPosition.y;
    return {
      distance: Math.sqrt(dx * dx + dy * dy),
      newPosition: { x: Math.round(x), y: Math.round(y) }
    };
  }

  static getElementData(element: HTMLElement): ElementData {
    return {
      tag: element.tagName,
      id: element.id,
      class: element.className,
      path: this.getElementPath(element),
      text: element.textContent?.slice(0, 100)
    };
  }

  static getScrollData(): ScrollData {
    // Get the viewport height, accounting for mobile browser chrome
    const viewportHeight = (() => {
      // iOS Safari specific handling
      if (typeof window.visualViewport !== 'undefined' && window.visualViewport) {
        return window.visualViewport.height;
      }
      return window.innerHeight || document.documentElement.clientHeight;
    })();

    // Get the total document height, accounting for dynamic content
    const documentHeight = Math.max(
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight,
      document.body.scrollHeight,
      document.body.offsetHeight
    );

    // Get current scroll position, accounting for different mobile browsers
    const scrollPosition = (() => {
      if (typeof window.pageYOffset !== 'undefined') {
        return window.pageYOffset;
      }
      // For older browsers
      const doc = document.documentElement;
      const body = document.body;
      return (doc && doc.scrollTop || body && body.scrollTop || 0);
    })();

    // Calculate scroll percentage
    const scrollPercent = Math.round(
      ((scrollPosition + viewportHeight) / documentHeight) * 100
    );

    // Ensure values are rounded and percentage is clamped between 0-100
    return {
      position: Math.round(scrollPosition),
      viewportHeight: Math.round(viewportHeight),
      documentHeight: Math.round(documentHeight),
      scrollPercent: Math.min(100, Math.max(0, scrollPercent))
    };
  }

  private static getElementPath(element: HTMLElement): string {
    const path: string[] = [];
    let current = element;

    while (current && current !== document.body) {
      let selector = current.tagName.toLowerCase();
      if (current.id) selector += `#${current.id}`;
      else if (current.className) selector += `.${current.className.split(" ").join(".")}`;
      path.unshift(selector);
      current = current.parentElement!;
    }

    return path.join(" > ");
  }

  // Add helper method for checking if device is mobile
  static isMobileDevice(): boolean {
    return (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1 ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
  }
}
