import { Position, UserMetrics } from './types';

export const debounce = <T extends (...args: any[]) => void>(
  fn: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};

export function throttle<T extends (...args: any[]) => any>(
  func: T,
  limit: number
): (...args: Parameters<T>) => void {
  let inThrottle: boolean;
  return function (this: any, ...args: Parameters<T>): void {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export function calculateScrollDepth(): number {
  const windowHeight = window.innerHeight;
  const documentHeight = Math.max(
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return Math.round((scrollTop / (documentHeight - windowHeight)) * 100);
}

export function calculateDistance(pos1: Position, pos2: Position): number {
  const dx = pos2.x - pos1.x;
  const dy = pos2.y - pos1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function cleanMetrics(metrics: UserMetrics): Partial<UserMetrics> {
  return Object.entries(metrics).reduce((cleaned: Partial<UserMetrics>, [key, value]) => {
    const metricKey = key as keyof UserMetrics;

    if (value !== 0 && value !== null && value !== false && value !== '') {
      cleaned[metricKey] = value;
    }

    return cleaned;
  }, {});
}
