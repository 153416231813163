import {
  UserMetrics,
  Position,
  TypedTrackingEvent,
  createTrackingEvent,
  EventType,
  BaseEvent,
  MoveEvent,
  ClickEvent,
  ScrollEvent,
  ExitIntentEvent
} from "./types";
import { EventHandlers } from './eventHandlers';
import { debounce, throttle, calculateScrollDepth, calculateDistance, cleanMetrics } from './utils';
import { UserContextManager } from "./userContextManager";

export class EventTracker {
  private readonly METRICS_UPDATE_INTERVAL = 1000;
  private maxScrollDepth = 0;
  private metricsInterval: NodeJS.Timeout | null = null;
  private isTracking: boolean = true;
  private eventListeners: Record<string, (e: Event) => void> = {};
  private moveHandler: (x: number, y: number) => void;
  private metrics: UserMetrics;
  private lastPosition: Position = { x: 0, y: 0 };
  private scrollTimeout: number | null = null;
  private moveTimeout: number | null = null;

  constructor(
    private sessionId: string,
    private moonId: string,
    private userContextManager: UserContextManager,
    metrics: UserMetrics,
    private trackEvent: (event: TypedTrackingEvent) => void
  ) {
    this.metrics = metrics;
    this.moveHandler = debounce(this.handleMove.bind(this), EventHandlers.DEBOUNCE_TIME);
    this.initializeTracking();
    this.startMetricsUpdates();
  }

  private initializeTracking(): void {
    if (!this.isMobile()) {
      this.setupExitIntentTracking();
    }

    this.setupEventListeners();
    this.setupScrollTracking();
  }

  private setupExitIntentTracking(): void {
    let shouldTrack = true;
    const sensitivity = 5;

    document.addEventListener('mouseleave', (e) => {
      if (e.clientY <= sensitivity && shouldTrack) {
        shouldTrack = false;
        this.metrics.exitAttempts++;

        const eventData = {
          position: { x: e.clientX, y: e.clientY }
        };

        this.trackEvent(
          createTrackingEvent<ExitIntentEvent>("exit_intent", eventData, {
            sessionId: this.sessionId,
            moonId: this.moonId,
            url: window.location.href,
            userContext: this.userContextManager.getContext(),
            metrics: this.metrics,
          })
        );

        // Reset after a delay
        setTimeout(() => {
          shouldTrack = true;
        }, 5000);
      }
    });
  }

  private setupEventListeners(): void {
    this.eventListeners = {
      mousemove: (e: Event) => this.moveHandler((e as MouseEvent).clientX, (e as MouseEvent).clientY),
      touchmove: (e: Event) => {
        const touch = (e as TouchEvent).touches[0];
        this.moveHandler(touch.clientX, touch.clientY);
      },
      click: (e: Event) => this.handleClick(e as MouseEvent),
      focusin: (e: Event) => this.handleFocusIn(e as FocusEvent),
      submit: (e: Event) => this.handleSubmit(e),
      copy: () => this.handleCopy(),
    };

    Object.entries(this.eventListeners).forEach(([event, handler]) => {
      document.addEventListener(event, handler);
    });

    document.addEventListener('input', (e: Event) => {
      if (!this.isTracking) return;
      const target = e.target as HTMLInputElement;
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        this.metrics.charactersTyped++;
        this.metrics.formCompletionRate = Math.max(
          this.metrics.formCompletionRate,
          this.calculateFieldCompletionRate(target)
        );
      }
    });

    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (!this.isTracking) return;
      if (e.key === 'Backspace') {
        this.metrics.backspaces++;
      }
    });

    document.addEventListener('selectionchange', () => {
      if (!this.isTracking) return;
      const selection = window.getSelection();
      if (selection && selection.toString().trim().length > 0) {
        this.metrics.textSelections++;
        this.handleEvent("text_selection", {
          selection: selection.toString()
        });
      }
    });

    document.addEventListener('play', (e) => {
      if (!this.isTracking) return;
      if (e.target instanceof HTMLMediaElement) {
        this.metrics.mediaInteractions.plays++;
        this.handleEvent("media_interaction", {
          action: "play",
          mediaId: e.target.id,
          mediaType: e.target.tagName.toLowerCase(),
          time: e.target.currentTime,
          duration: e.target.duration
        });
      }
    }, true);

    document.addEventListener('pause', (e) => {
      if (!this.isTracking) return;
      if (e.target instanceof HTMLMediaElement) {
        this.metrics.mediaInteractions.pauses++;
        this.handleEvent("media_interaction", {
          action: "pause",
          mediaId: e.target.id,
          mediaType: e.target.tagName.toLowerCase(),
          time: e.target.currentTime,
          duration: e.target.duration
        });
      }
    }, true);

    document.addEventListener('ended', (e) => {
      if (!this.isTracking) return;
      if (e.target instanceof HTMLMediaElement) {
        this.metrics.mediaInteractions.completions++;
        this.handleEvent("media_interaction", {
          action: "complete",
          mediaId: e.target.id,
          mediaType: e.target.tagName.toLowerCase(),
          time: e.target.currentTime,
          duration: e.target.duration
        });
      }
    }, true);
  }

  private removeEventListeners(): void {
    if (this.eventListeners) {
      Object.entries(this.eventListeners).forEach(([event, handler]) => {
        document.removeEventListener(event, handler);
      });
    }
  }

  private setupScrollTracking(): void {
    const handleScroll = debounce(() => {
      const scrollData = EventHandlers.getScrollData();

      if (scrollData.scrollPercent > this.maxScrollDepth) {
        this.maxScrollDepth = scrollData.scrollPercent;
        this.metrics.scrollDepth = this.maxScrollDepth;
      }

      this.metrics.scrollDepth = Math.max(this.metrics.scrollDepth, scrollData.scrollPercent);

      this.trackEvent(
        createTrackingEvent<ScrollEvent>("scroll", {
          depth: scrollData.scrollPercent,
          position: scrollData.position,
          viewportHeight: scrollData.viewportHeight,
          documentHeight: scrollData.documentHeight
        }, {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
          metrics: this.metrics,
        })
      );
    }, 250);

    const events = ['scroll', 'touchmove', 'touchend'];

    events.forEach(eventName => {
      window.addEventListener(eventName, handleScroll, { passive: true });
    });

    handleScroll();

    let lastTouchY = 0;
    let momentumTimeout: NodeJS.Timeout | null = null;

    window.addEventListener('touchstart', (e) => {
      lastTouchY = e.touches[0].clientY;
      if (momentumTimeout) {
        clearTimeout(momentumTimeout);
      }
    }, { passive: true });

    window.addEventListener('touchend', () => {
      momentumTimeout = setTimeout(handleScroll, 100);
    }, { passive: true });

    window.addEventListener('orientationchange', () => {
      setTimeout(handleScroll, 100);
    }, { passive: true });
  }

  private startMetricsUpdates(): void {
    this.metricsInterval = setInterval(() => {
      if (this.isTracking) {
        this.metrics.timeOnPage += 1;
      }
    }, this.METRICS_UPDATE_INTERVAL);
  }

  private isMobile(): boolean {
    return /Mobile|Android|iOS/i.test(navigator.userAgent);
  }

  public updateMetrics(callback: (metrics: Pick<UserMetrics, "timeOnPage" | "scrollDepth" | "interactions">) => void): void {
    callback({
      timeOnPage: this.metrics.timeOnPage,
      scrollDepth: this.metrics.scrollDepth,
      interactions: this.metrics.interactions
    });
  }

  public stopTracking(): void {
    this.isTracking = false;
    if (this.metricsInterval) {
      clearInterval(this.metricsInterval);
      this.metricsInterval = null;
    }

    this.removeEventListeners();
  }

  private handleMove(x: number, y: number): void {
    if (!this.isTracking) return;

    const { distance, newPosition } = EventHandlers.handleMove(x, y, this.lastPosition);
    this.metrics.mouseDistance += distance;
    this.metrics.lastPosition = newPosition;
    this.lastPosition = newPosition;

    this.trackEvent(
      createTrackingEvent<MoveEvent>("move", {
        distance: this.metrics.mouseDistance,
        position: newPosition
      }, {
        sessionId: this.sessionId,
        moonId: this.moonId,
        url: window.location.href,
        userContext: this.userContextManager.getContext(),
        metrics: this.metrics,
      })
    );
  }

  private handleFocusIn(e: FocusEvent): void {
    if (!this.isTracking) return;

    const target = e.target as HTMLElement;
    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.tagName === 'SELECT') {
      this.metrics.formInteractions++;
      this.metrics.inputAttempts++;

      if (target instanceof HTMLInputElement) {
        const completionRate = this.calculateFieldCompletionRate(target);
        this.metrics.formCompletionRate = Math.max(this.metrics.formCompletionRate, completionRate);
      }

      this.handleEvent("form_interaction", {
        elementType: target.tagName.toLowerCase(),
        elementId: target.id || '',
        elementName: (target as HTMLInputElement).name || '',
        inputType: (target as HTMLInputElement).type || ''
      });
    }
  }

  private calculateFieldCompletionRate(input: HTMLInputElement): number {
    const minLength = input.minLength || 3;
    return Math.min((input.value.length / minLength) * 100, 100);
  }

  private handleSubmit(e: Event): void {
    if (!this.isTracking) return;

    const form = e.target as HTMLFormElement;
    this.metrics.formInteractions++;
    this.handleEvent("form_interaction", {
      elementType: 'form',
      elementId: form.id || '',
      elementName: form.name || '',
      inputType: 'submit'
    });
  }

  private handleCopy(): void {
    if (!this.isTracking) return;

    this.metrics.copyEvents++;
    this.handleEvent("copy", {});
  }

  private updateTotalInteractions(): void {
    this.metrics.totalInteractions = (
      this.metrics.formInteractions +
      this.metrics.copyEvents +
      this.metrics.textSelections +
      this.metrics.mediaInteractions.plays +
      this.metrics.mediaInteractions.pauses +
      this.metrics.mediaInteractions.completions
    );
  }

  private handleEvent(eventType: EventType, data: any = {}): void {
    if (!this.isTracking) return;

    // Send the event without metrics
    this.trackEvent(
      createTrackingEvent(eventType, data, {
        sessionId: this.sessionId,
        moonId: this.moonId,
        url: window.location.href,
        userContext: this.userContextManager.getContext(),
      })
    );

  }

  private lastSentMetrics: string = '';
  private metricsUpdateTimeout: NodeJS.Timeout | null = null;


  // Event handlers
  private handleScroll = debounce(() => {
    if (!this.isTracking) return;

    const scrollDepth = calculateScrollDepth();
    this.metrics.scrollDepth = Math.max(this.metrics.scrollDepth, scrollDepth);

    this.handleEvent("scroll", {
      depth: scrollDepth,
      position: window.scrollY,
      viewportHeight: window.innerHeight,
      documentHeight: document.documentElement.scrollHeight
    });
  }, 100);

  private handleMouseMove = throttle((e: MouseEvent) => {
    if (!this.isTracking) return;

    const newPosition = { x: e.clientX, y: e.clientY };
    const distance = calculateDistance(this.lastPosition, newPosition);

    this.metrics.mouseDistance += distance;
    this.metrics.lastPosition = newPosition;
    this.lastPosition = newPosition;

    this.handleEvent("move", {
      distance,
      position: newPosition
    });
  }, 100);

  private handleClick = (e: MouseEvent) => {
    if (!this.isTracking) return;

    this.metrics.clickCount++;
    this.metrics.interactions++;
    const element = e.target as HTMLElement;

    this.handleEvent("click", {
      position: { x: e.clientX, y: e.clientY },
      element: element ? {
        tag: element.tagName.toLowerCase(),
        id: element.id,
        class: element.className,
        text: element.textContent?.trim()
      } : undefined
    });
  };
}

export function createDefaultMetrics(): UserMetrics {
  return {
    // Core metrics
    timeOnPage: 0,
    scrollDepth: 0,
    interactions: 0,
    totalInteractions: 0,
    mouseDistance: 0,
    clickCount: 0,
    exitAttempts: 0,
    lastPosition: { x: 0, y: 0 },

    // Form metrics
    formInteractions: 0,
    inputAttempts: 0,
    charactersTyped: 0,
    backspaces: 0,
    formCompletionRate: 0,
    submitAttempts: 0,
    validationErrors: 0,

    // Content interaction metrics
    copyEvents: 0,
    textSelections: 0,
    mediaInteractions: {
      plays: 0,
      pauses: 0,
      completions: 0
    },

    // Dialog metrics
    timeShown: 0,
    timeToFirstInteraction: null,
    hoverDuration: 0,
    focusDuration: 0,
    closeType: null,

    // Form state tracking
    emailStarted: false,
    nameStarted: false,
    emailCompleted: false,
    nameCompleted: false
  };
}

