declare global {
  interface Window {
    moonflowsSight?: any;
  }
}

import { createDefaultMetrics, EventTracker } from "./eventTracker";
import { UserContextManager } from "./userContextManager";
import {
  BatchConfig,
  TypedTrackingEvent,
  UserMetrics,
  UserContext,
  ServerCommand,
  BatchResponse,
  createTrackingEvent,
  PageChangedEvent,
  EventWrapper,
} from "./types";
import { FeedbackDialogManager } from "./dialogSetup/feedbackDialogManager";
import { SignupDialogManager } from "./dialogSetup/signupDialogManager";
import { cleanMetrics } from "./utils";

interface BatchRequestBody {
  events: EventWrapper[];
  timestamp: number;
  moonId: string;
  sessionId: string;
  url: string;
  contextId: string;
  metrics?: Partial<UserMetrics>;
}

interface ContextRequestBody {
  context: UserContext;
  sessionId: string;
  timestamp: number;
  fingerprint: string;
  moonId: string;
  currentUrl: string;
  referrerUrl?: string;
  pathname: string;
  hostname: string;
}

interface ContextResponse {
  contextId: string;
  // Add other response fields if needed
}

interface FinalizeData {
  events: TypedTrackingEvent[];
  sessionId: string;
  finalMetrics: UserMetrics;
  fingerprint: string;
}

// Define dialog type configuration
type DialogType = "feedback" | "signup";
interface DialogConfig {
  type: DialogType;
  // Add any other configuration options here
}

export class TrackingSystem {
  private contextId: string | null = null;
  private eventQueue: TypedTrackingEvent[] = [];
  private lastSendTime: number = Date.now();
  private metrics: UserMetrics;
  private readonly sessionId: string;
  private readonly batchConfig: BatchConfig;
  private readonly userContextManager: UserContextManager;
  private eventTracker!: EventTracker;
  private dialogManager!: FeedbackDialogManager | SignupDialogManager;
  private lastEventTimestamps: Record<string, number> = {};
  private lastEventData: {
    position?: { x: number, y: number };
    depth?: number;
    elementId?: string;
    metrics?: {
      timeShown: number;
      interactions: number;
    };
  } = {};
  private moonId: string;
  private isDialogComplete: boolean;
  private isPageChanged: boolean;
  public isDialogClosed: boolean;
  private _lastCleanup: number = 0;
  private batchTimeout: NodeJS.Timeout | null = null;
  private isProcessingBatch = false;

  constructor() {
    console.log('TrackingSystem constructor called');
    this.sessionId = `sess_${Date.now()}_${Math.random().toString(36).slice(2)}`;
    this.batchConfig = {
      maxSize: 10,
      interval: 1000,
      maxRetries: 3,
      retryDelay: 1000,
    };
    this.metrics = this.initializeMetrics();
    this.userContextManager = new UserContextManager();

    this.trackEvent = this.trackEvent.bind(this);
    this.isDuplicateEvent = this.isDuplicateEvent.bind(this);
    this.isDialogComplete = false;
    this.isDialogClosed = false;
    this.isPageChanged = false;

    // Get moonId from script tag
    this.moonId = "";
    try {
      this.moonId = this.getMoonId();
    } catch (err) {
      console.error(err);
      return;
    }

    if (!this.moonId) {
      console.error(
        "No moonId found. Make sure to include moonId attribute in the script tag.",
      );
    }
  }

  private async getDialogConfig(): Promise<DialogConfig> {
    // tr
    //   // In the future, this would be an API call
    //   const response = await fetch("https://api.moonflows.com/api/dialog-config");
    //   const config = await response.json();
    //   return config;
    // } catch (error) {
    //   console.error("Failed to fetch dialog config:", error);
    //   // Default to feedback dialog if config fetch fails
    //   return {
    //     type: "feedback" as DialogType,
    //   };
    // }

    return {
      type: "feedback" as DialogType,
    };
  }

  private createDialogManager(type: DialogType): FeedbackDialogManager | SignupDialogManager {
    switch (type) {
      case "feedback":
        return new FeedbackDialogManager(
          this.trackEvent,
          this.sessionId,
          this.moonId,
          this.userContextManager,
          this.metrics
        );
      case "signup":
        return new SignupDialogManager(
          this.trackEvent,
          this.sessionId,
          this.moonId,
          this.userContextManager,
          this.metrics
        );
      default:
        console.warn(`Unknown dialog type: ${type}, defaulting to feedback`);
        return new FeedbackDialogManager(
          this.trackEvent,
          this.sessionId,
          this.moonId,
          this.userContextManager,
          this.metrics
        );
    }
  }

  private async initializeDialog(): Promise<void> {
    try {
      const dialogConfig = await this.getDialogConfig();
      this.dialogManager = this.createDialogManager(dialogConfig.type);
    } catch (error) {
      console.error("Error initializing dialog, defaulting to feedback:", error);
      this.dialogManager = this.createDialogManager("feedback");
    }
  }

  private getMoonId(): string {
    // Find the current script
    const scripts = document.getElementsByTagName("script");
    const currentScript = scripts[scripts.length - 1];

    // Try getting moonId from data attribute
    let moonId: string | null = currentScript.getAttribute("data-moon-id");

    // If not found, search all script tags for flow.js with moonId
    if (!moonId) {
      const flowScript = Array.from(scripts).find((script) => {
        const src = script.src || "";
        return src.includes("flow.js") && script.hasAttribute("data-moon-id");
      });

      if (flowScript) {
        moonId = flowScript.getAttribute("data-moon-id");
      }
    }

    if (!moonId) {
      throw new Error(
        "moonId not found in script tag attributes. Add data-moon-id attribute to the script tag.",
      );
    }

    return moonId;
  }

  private initializeMetrics(): UserMetrics {
    return createDefaultMetrics();
  }

  private async establishContext(): Promise<string | null> {
    try {
      const contextRequest: ContextRequestBody = {
        context: this.userContextManager.getContext(),
        sessionId: this.sessionId,
        timestamp: Date.now(),
        fingerprint: this.userContextManager.getFrontendFingerprint(),
        moonId: this.moonId,
        currentUrl: window.location.href,
        referrerUrl: document.referrer,
        pathname: window.location.pathname,
        hostname: window.location.hostname
      };

      const response = await fetch("https://api.moonflows.com/api/context", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(contextRequest),
      });

      if (!response.ok) {
        throw new Error(`Context request failed: ${response.statusText}`);
      }

      const data: ContextResponse = await response.json();

      // Store the contextId (which is the backend fingerprint) in userContextManager
      this.userContextManager.setContextId(data.contextId);

      return data.contextId;
    } catch (error) {
      console.error("Failed to establish context:", error);
      return null;
    }
  }

  private isDuplicateEvent(
    type: TypedTrackingEvent["type"],
    data: unknown,
  ): boolean {
    const now = Date.now();
    const lastTimestamp = this.lastEventTimestamps[type] || 0;

    // Define minimum intervals (in ms) between events of each type
    const MIN_INTERVALS: Partial<Record<TypedTrackingEvent["type"] | "focus" | "blur", number>> = {
      move: 100,        // Only track mouse moves every 100ms
      scroll: 250,      // Only track scroll events every 250ms
      form_interaction: 100,
      metrics_update: 1000,  // Metrics updates once per second is plenty
      focus: 250,
      blur: 250,
      click: 50,        // Allow clicks to register more frequently
      hover_start: 100,
      hover_end: 100,
      input_change: 250,
      input_backspace: 250,
      validation_error: 100,
      step_transition: 100,
      // Add other event types as needed
    };

    const minInterval = MIN_INTERVALS[type] || 0;

    // Basic time-based deduplication
    if (now - lastTimestamp < minInterval) {
      return true;
    }

    // Update timestamp first
    this.lastEventTimestamps[type] = now;

    // For events that need more sophisticated deduplication
    switch (type) {
      case "move": {
        const currPos = (data as { position: { x: number, y: number } })?.position;
        const lastPos = this.lastEventData.position;

        if (!lastPos || !currPos) {
          this.lastEventData.position = currPos;
          return false;
        }

        // Calculate distance using manhattan distance (more performant than euclidean)
        const distance = Math.abs(currPos.x - lastPos.x) + Math.abs(currPos.y - lastPos.y);
        const isDuplicate = distance < 20; // Increased threshold, less events

        if (!isDuplicate) {
          this.lastEventData.position = currPos;
        }
        return isDuplicate;
      }

      case "scroll": {
        const currDepth = (data as { depth: number })?.depth;
        const lastDepth = this.lastEventData.depth;

        if (lastDepth === undefined || currDepth === undefined) {
          this.lastEventData.depth = currDepth;
          return false;
        }

        const isDuplicate = Math.abs(currDepth - lastDepth) < 10; // Increased threshold

        if (!isDuplicate) {
          this.lastEventData.depth = currDepth;
        }
        return isDuplicate;
      }

      case "form_interaction": {
        const currData = data as { elementId: string };
        const lastElementId = this.lastEventData.elementId;

        const isDuplicate = currData.elementId === lastElementId;

        if (!isDuplicate) {
          this.lastEventData.elementId = currData.elementId;
        }
        return isDuplicate;
      }

      case "metrics_update": {
        const currMetrics = (data as any)?.metrics;
        const lastMetrics = this.lastEventData.metrics;

        if (!lastMetrics || !currMetrics) {
          this.lastEventData.metrics = {
            timeShown: currMetrics?.timeShown || 0,
            interactions: currMetrics?.interactions || 0
          };
          return false;
        }

        // Simplified metrics comparison - only track significant changes
        const timeChange = Math.abs((currMetrics.timeShown || 0) - lastMetrics.timeShown);
        const interactionChange = Math.abs(
          (currMetrics.interactions || 0) - lastMetrics.interactions
        );

        const isDuplicate = timeChange < 2 && interactionChange < 1;

        if (!isDuplicate) {
          this.lastEventData.metrics = {
            timeShown: currMetrics.timeShown || 0,
            interactions: currMetrics.interactions || 0
          };
        }
        return isDuplicate;
      }

      default:
        return false;
    }
  }

  private trackEvent(inputEvent: TypedTrackingEvent): void {
    // Early return if no context
    if (!this.contextId) {
      return;
    }

    // Early return if dialog is already shown
    if (this.isDialogClosed || this.isDialogComplete) {
      return;
    }

    // Set dialog state flags based on specific events
    if (inputEvent.type === "dialog_closed") {
      this.isDialogClosed = true;
    } else if (inputEvent.type === "submit_success") {
      this.isDialogComplete = true;
    }

    // Only check page change state
    if (this.isPageChanged) {
      console.log("Page Changed - ignoring subsequent events");
      return;
    }

    // Set the page changed flag if this is a page change event
    if (inputEvent.type === "page_changed") {
      this.isPageChanged = true;
    }

    // Set the dialog complete flag if this is a feedback submission event
    if (inputEvent.type === "feedback_submitted") {
      this.isDialogComplete = true;
    }

    // Avoid processing if the event is a duplicate
    if (this.isDuplicateEvent(inputEvent.type, inputEvent.data)) {
      console.log(`Filtered duplicate event: ${inputEvent.type}`);
      return;
    }

    // Create the event using createTrackingEvent
    const event = createTrackingEvent(inputEvent.type, inputEvent.data, {
      sessionId: this.sessionId,
      moonId: this.moonId,
      url: window.location.href,
      userContext: this.userContextManager.getContext(),
    });

    // Add event to the queue
    this.eventQueue.push(event);

    // Trigger immediate batch if queue is full
    if (this.eventQueue.length >= this.batchConfig.maxSize) {
      void this.sendBatch();
    }
  }

  private async sendBatch(): Promise<void> {
    if (this.isProcessingBatch || this.eventQueue.length === 0) return;

    try {
      this.isProcessingBatch = true;

      if (!this.contextId) {
        this.contextId = await this.establishContext();
        if (!this.contextId) return;
      }

      const batchSize = Math.min(this.eventQueue.length, this.batchConfig.maxSize);
      const batch = this.eventQueue.slice(0, batchSize);

      const requestBody = this.createBatchRequestBody(batch);
      await this.sendBatchRequest(requestBody);

      this.eventQueue = this.eventQueue.slice(batchSize);
      this.lastSendTime = Date.now();

    } catch (error) {
      this.handleBatchError(error);
    } finally {
      this.isProcessingBatch = false;
    }
  }

  private createBatchRequestBody(batch: TypedTrackingEvent[]): BatchRequestBody {
    return {
      events: batch.map(({ userContext, ...event }) => ({
        type: event.type,
        data: event.data,
      })),
      metrics: cleanMetrics(this.metrics),
      timestamp: Date.now(),
      sessionId: this.sessionId,
      url: window.location.href,
      moonId: this.moonId,
      contextId: this.contextId!,
    };
  }

  private async sendBatchRequest(requestBody: BatchRequestBody): Promise<void> {
    const response = await fetch("https://api.moonflows.com/api/update", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
      signal: AbortSignal.timeout(5000),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json() as BatchResponse;
    if (data.result && data.result === "DIALOG_ALREADY_SHOWN") {
      this.isDialogClosed = true;
      this.isDialogComplete = true;
      return;
    }

    if (data.commands?.length) {
      data.commands.forEach(this.handleServerCommand.bind(this));
    }
  }

  private handleServerCommand(command: ServerCommand): void {
    switch (command.type) {
      case "SHOW_DIALOG":
        if (this.isDialogClosed || this.isDialogComplete) {
          console.log("Dialog already shown or completed - ignoring command");
          return;
        }
        this.dialogManager.handleServerCommand({
          action: "show_dialog",
          ...command.payload,
        });
        break;
      default:
        console.warn("Unknown command type:", command.type);
    }
  }

  private handlePageChange(): void {
    // Stop the current event tracker
    if (this.eventTracker) {
      this.eventTracker.stopTracking();
    }

    // Set page changed flag
    this.isPageChanged = true;

    // Clear any existing dialog state
    this.isDialogClosed = false;
    this.isDialogComplete = false;

    // Send any remaining events in the queue
    if (this.eventQueue.length > 0) {
      void this.sendBatch();
    }

    // Track the page change event
    this.trackEvent(
      createTrackingEvent<PageChangedEvent>(
        "page_changed",
        {},
        {
          sessionId: this.sessionId,
          moonId: this.moonId,
          url: window.location.href,
          userContext: this.userContextManager.getContext(),
        },
      ),
    );
  }

  private setupPageChangeTracking(): void {
    let lastPathname = window.location.pathname;

    // Listen for history changes
    window.addEventListener('popstate', () => {
      const currentPathname = window.location.pathname;
      if (currentPathname !== lastPathname) {
        this.handlePageChange();
        lastPathname = currentPathname;
      }
    });

    // Intercept pushState and replaceState
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = function (...args) {
      originalPushState.apply(this, args);
      const currentPathname = window.location.pathname;
      if (currentPathname !== lastPathname) {
        window.dispatchEvent(new Event('popstate'));
        lastPathname = currentPathname;
      }
    };

    history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      const currentPathname = window.location.pathname;
      if (currentPathname !== lastPathname) {
        window.dispatchEvent(new Event('popstate'));
        lastPathname = currentPathname;
      }
    };
  }

  public async initialize(): Promise<void> {
    console.log('TrackingSystem initialize called');
    if (!this.moonId) {
      console.error("Cannot initialize tracking system without moonId");
      return;
    }

    try {
      await this.userContextManager.initialize();
      console.log('Frontend fingerprint generated');

      const contextId = await this.establishContext();
      if (!contextId) {
        console.error('Failed to establish context with backend');
        return;
      }

      this.contextId = contextId;

      this.eventTracker = new EventTracker(
        this.sessionId,
        this.moonId,
        this.userContextManager,
        this.metrics,
        this.trackEvent.bind(this)
      );

      await this.initializeDialog();
      this.setupCleanup();
      this.startBatchInterval();
      this.setupPageChangeTracking();
    } catch (error) {
      console.error('Error during initialization:', error);
      throw error;
    }
  }

  private setupCleanup(): void {
    const sendFinalData = (data: FinalizeData): void => {
      if (navigator.sendBeacon) {
        const blob = new Blob([JSON.stringify(data)], {
          type: "application/json",
        });
        navigator.sendBeacon("https://api.moonflows.com/api/update", blob);
      } else {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://api.moonflows.com/api/update", false);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify(data));
      }
    };

    const getFinalData = (): FinalizeData => ({
      events: this.eventQueue,
      sessionId: this.sessionId,
      finalMetrics: this.metrics,
      fingerprint: this.contextId as string,
    });

    window.addEventListener("beforeunload", () => {
      if (this.eventQueue.length > 0) {
        sendFinalData(getFinalData());
      }
    });

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        void this.sendBatch();
      }
    });

    window.addEventListener("pagehide", () => {
      if (this.eventQueue.length > 0) {
        sendFinalData(getFinalData());
      }
    });
  }

  private startBatchInterval(): void {
    const scheduleBatch = () => {
      if (this.batchTimeout) {
        clearTimeout(this.batchTimeout);
      }

      this.batchTimeout = setTimeout(async () => {
        if (this.eventQueue.length > 0) {
          await this.sendBatch();
        }
        scheduleBatch();
      }, this.batchConfig.interval);
    };

    scheduleBatch();
  }

  private cleanupStoredData(): void {
    const now = Date.now();
    const CLEANUP_THRESHOLD = 60000; // 1 minute

    // Clean up old timestamps
    Object.keys(this.lastEventTimestamps).forEach(key => {
      if (now - this.lastEventTimestamps[key] > CLEANUP_THRESHOLD) {
        delete this.lastEventTimestamps[key];
      }
    });

    // Reset stored data periodically
    if (now - (this._lastCleanup || 0) > CLEANUP_THRESHOLD) {
      this.lastEventData = {};
      this._lastCleanup = now;
    }
  }

  private handleBatchError(error: any): void {
    console.error("Failed to send metrics batch:", error);
    if (error instanceof Response && error.status === 404) {
      this.contextId = null;
    }
  }
}

if (typeof window !== 'undefined') {
  const initializeTracker = () => {
    try {
      console.log('Initializing Moon Flows tracking system...');
      const trackingSystem = new TrackingSystem();
      window.moonflowsSight = trackingSystem; // Make it accessible globally
      trackingSystem.initialize().catch((error) => {
        console.error('Failed to initialize tracking system:', error);
      });
    } catch (error) {
      console.error('Failed to create tracking system:', error);
    }
  };

  // Initialize on DOMContentLoaded or immediately if document is already loaded
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeTracker);
  } else {
    initializeTracker();
  }
}
